.hotel-marker .price-tag {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px 10px;
  position: relative;
  border: 1px solid white;
}

.hotel-marker .price-tag:hover {
  cursor: pointer;
  /* opacity: 1; */
  font-weight: bold;
  background-color: #125dda;
}

.hotel-marker .price-tag::after,
.hotel-marker .details::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}

.hotel-marker .details::after {
  border-top-color: white;
}

.hotel-marker .price-tag:hover::after {
  border-top: 8px solid #125dda;
}

.hotel-marker .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.hotel-marker.highlight .details {
  display: flex;
}

@media all and (min-width:600px) {
  .hotel-marker.highlight .price-tag {
    display: none;
  }
}

.hotel-marker.highlight {
  background-color: #FFFFFF;
  /* border-radius: 8px; */
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: auto;
  width: auto;
}

.hotel-marker.highlight::after {
  border-top: 9px solid #FFFFFF;
}
